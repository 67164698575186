import React from 'react';
import { string, bool, arrayOf, oneOfType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const KeyProductFeatureName = (props) => {
  const { name, simple } = props;
  return (
    <>
      {simple ? (
        <>
          <div className="sui-flex sui-w-1/2">
            <Typography truncate variant="body-xs" color="subtle">{name}</Typography>
          </div>
        </>
      ) : (
        <Typography variant="h4">{name}</Typography>
      )}
    </>
  );
};

KeyProductFeatureName.propTypes = {
  name: oneOfType([string, arrayOf(string)]),
  simple: bool
};

KeyProductFeatureName.defaultProps = {
  name: null,
  simple: false
};

export default KeyProductFeatureName;