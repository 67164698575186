/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useState, useEffect } from 'react';
import {
  number, string, oneOf, bool, arrayOf
} from 'prop-types';
import {
  useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getKpfFromSpecifications, filterAttributeDuplicates, returnObject } from '../helper';
import KeyProductFeatureContents from './KeyProductFeatureContents';
import dataModel from '../dataModel';

const KeyProductFeatures = (props) => {

  const {
    hideImage,
    hideForProductTypes,
    itemId,
    minfeatures,
    maxfeatures,
    displaySimilarLinks,
    twoColumnGrid,
    oneColumnGrid,
    simple,
    forceMobile
  } = props;
  const { channel } = useContext(ExperienceContext);

  const [kpfData, setKpfData] = useState({
    keyProductFeaturesItems: []
  });

  const { data } = useDataModel('product', {
    variables: { itemId }
  });

  const { product } = returnObject(data);

  const { keyProductFeatures } = returnObject(product);

  useEffect(() => {
    if (keyProductFeatures) {
      setKpfData({
        keyProductFeaturesItems: keyProductFeatures?.keyProductFeaturesItems
      });
    }
  }, [itemId, keyProductFeatures]);

  if (!keyProductFeatures?.keyProductFeaturesItems?.length && !kpfData.keyProductFeaturesItems.length) {
    return null;
  }
  const shouldHide = hideForProductTypes?.includes(product?.info?.productSubType?.name);

  if (shouldHide) {
    return null;
  }

  const keyProductFeaturesItems = keyProductFeatures?.keyProductFeaturesItems || kpfData.keyProductFeaturesItems;
  const features = keyProductFeaturesItems?.[0]?.features || [];

  let featuresAttr = filterAttributeDuplicates({ features, product });

  if (featuresAttr?.length < minfeatures && channel === 'mobile') {
    featuresAttr = getKpfFromSpecifications(product)
      .map((spec) => ({
        name: spec.specName,
        value: spec.specValue
      }));
  }

  if (featuresAttr?.length < minfeatures) {
    return null;
  }

  const url = hideImage ? null : product.media?.image?.url;

  return (
    <KeyProductFeatureContents
      url={url}
      featuresAttr={featuresAttr}
      oneColumnGrid={oneColumnGrid}
      twoColumnGrid={twoColumnGrid}
      simple={simple}
      forceMobile={forceMobile}
      maxfeatures={maxfeatures}
      displaySimilarLinks={displaySimilarLinks}
    />
  );
};

KeyProductFeatures.displayName = 'KeyProductFeatures';

KeyProductFeatures.dataModel = dataModel;

KeyProductFeatures.propTypes = {
  storeId: string,
  displaySimilarLinks: bool,
  hideImage: bool,
  hideForProductTypes: arrayOf(string),
  itemId: string.isRequired,
  maxfeatures: oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  minfeatures: number,
  twoColumnGrid: bool,
  oneColumnGrid: bool,
  simple: bool,
  forceMobile: bool
};

KeyProductFeatures.defaultProps = {
  displaySimilarLinks: false,
  hideImage: false,
  hideForProductTypes: [],
  maxfeatures: 8,
  minfeatures: 3,
  twoColumnGrid: false,
  oneColumnGrid: false,
  simple: false,
  storeId: null,
  forceMobile: false
};

export { KeyProductFeatures };