import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import KeyProductFeatureName from './KeyProductFeatureName';
import { KeyProductFeatureValue } from './KeyProductFeatureValue';

const specBlock = (feature, simple) => (
  <div className={'sui-flex ' + (simple ? 'sui-items-center sui-gap-1' : 'sui-flex-col sui-gap-3')}>
    <KeyProductFeatureName name={feature.name} simple={simple} />
    <KeyProductFeatureValue value={feature.value} simple={simple} />
  </div>
);

const similarItemBlock = (feature, simple) => (
  <div className="sui-flex sui-flex-col sui-gap-3">
    {specBlock(feature, simple)}
    <Link
      href={feature.refinementUrl}
      data-testid="similar-items-link"
      variant={simple ? 'body-sm' : 'body-base'}
    >
      See Similar Items
    </Link>
  </div>
);

const keyProductFeatureSpecs = (featuresAttr, maxfeatures, displaySimilarLinks, simple) => (
  featuresAttr.slice(0, maxfeatures).map((feature) => (
    <div key={feature.refinementId} className={simple ? '' : 'sui-py-6'}>
      {feature.refinementUrl && feature.value.length === 1 && displaySimilarLinks ? (
        <a
          className="hover:sui-no-underline"
          href={feature.refinementUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {similarItemBlock(feature, simple)}
        </a>
      ) : (
        <div className="sui-w-full">
          {specBlock(feature, simple)}
        </div>
      )}
    </div>
  ))
);

const KeyProductFeatureContents = ({
  url, featuresAttr, oneColumnGrid, twoColumnGrid, simple, forceMobile, maxfeatures, displaySimilarLinks
}) => {
  const specClasses = classNames(
    'sui-grid',
    simple ? 'sui-gap-2' : 'sui-gap-6',
    {
      'sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-3': !oneColumnGrid && !twoColumnGrid,
      'sui-grid-cols-1 md:sui-grid-cols-1 lg:sui-grid-cols-1': oneColumnGrid,
      'sui-grid-cols-2 md:sui-grid-cols-2 lg:sui-grid-cols-2': twoColumnGrid
    }
  );

  const defaultClasses = classNames('sui-flex sui-flex-col lg:sui-flex-row sui-w-full sui-gap-6 sui-overflow-hidden');
  const mobileClasses = classNames('sui-flex sui-flex-col sui-w-full sui-gap-6 sui-overflow-hidden');

  return (
    <div
      data-component="KeyProductFeatures"
      className={forceMobile ? mobileClasses : defaultClasses}
      data-testid={forceMobile ? 'kpf-mobile' : 'kpf-desktop'}
    >
      {url && (
        <div className="sui-flex sui-justify-center sui-items-center sui-min-w-fit">
          <img
            className="sui-object-contain sui-w-96 lg:sui-w-80"
            src={url || ''}
            alt="product"
            height="1"
            width="1"
          />
        </div>
      )}
      <div className="sui-grow">
        <div className={specClasses} data-testid="key-product-feature-list">
          {keyProductFeatureSpecs(featuresAttr, maxfeatures, displaySimilarLinks, simple)}
        </div>
      </div>
    </div>
  );
};

KeyProductFeatureContents.propTypes = {
  url: PropTypes.string,
  featuresAttr: PropTypes.arrayOf(PropTypes.shape({
    refinementId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    refinementUrl: PropTypes.string
  })),
  oneColumnGrid: PropTypes.bool,
  twoColumnGrid: PropTypes.bool,
  simple: PropTypes.bool,
  forceMobile: PropTypes.bool,
  maxfeatures: PropTypes.number,
  displaySimilarLinks: PropTypes.bool
};

KeyProductFeatureContents.defaultProps = {
  url: '',
  featuresAttr: [],
  oneColumnGrid: false,
  twoColumnGrid: false,
  simple: false,
  forceMobile: false,
  maxfeatures: 8,
  displaySimilarLinks: false
};

export default KeyProductFeatureContents;