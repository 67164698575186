export const returnObject = (shouldBeObject) => {
  if (typeof shouldBeObject === 'object' && shouldBeObject !== null) {
    return shouldBeObject;
  }

  return {};
};

export const getSortedValue = ({ attrGroupList = {} }) => {
  return (attrGroupList.specifications || [])
    .filter((attr) => attr.specName)
    .sort((entryName, compName) => {
      let nameval1 = entryName.specName.toUpperCase();
      let nameval2 = compName.specName.toUpperCase();
      if (nameval1 < nameval2) {
        return -1;
      }
      if (nameval1 > nameval2) {
        return 1;
      }
      return 0;
    });
};

export const getKpfFromSpecifications = (product) => {
  const { specificationGroup = [] } = returnObject(product);
  const kpfGroups = (specificationGroup || []).filter((group) => [
    'Dimensions',
    'Details'
  ].indexOf(group.specTitle) > -1)
    .map((attrGroupList) => {
      return getSortedValue({ attrGroupList });
    });
  if (!kpfGroups.length) {
    return [];
  }
  return kpfGroups.reduce(function (prev, curr) {
    return prev.concat(curr);
  });
};

export const filterAttributeDuplicates = ({ features, product }) => {
  const specAttributes = getKpfFromSpecifications(product)
    .map((spec) => ({
      name: spec.specName,
      value: spec.specValue
    }));
  const result = specAttributes.filter(
    (specNames) => features.find((specName) => specNames.name.includes(specName.name)));
  const specKpfValue = result.filter((specValues) => specValues.value.includes(','));
  return Object.values(features.reduce((filteredAttr, {
    name,
    refinementId,
    refinementUrl,
    value
  }) => {
    let filteredAttributes = filteredAttr;
    if (!filteredAttributes[name]?.modified) {
      filteredAttributes[name] = {
        name,
        refinementId,
        refinementUrl,
        value: [],
        modified: true
      };
    }
    filteredAttributes[name].value.push(value);
    specKpfValue.map((specs) => {
      if (specs.name === filteredAttributes[name].name) {
        filteredAttributes[name].value = specs.value && specs.value.split(',');
      }
      return filteredAttributes[name].value;
    });
    return filteredAttributes;
  }, {}));
};