import { arrayOf as arrayType, shape as shapeType, string as stringType, params } from '@thd-nucleus/data-sources';

// Define specs for specificationGroup
const specs = arrayType(
  shapeType({
    specifications: arrayType(
      shapeType({
        specName: stringType(),
        specValue: stringType()
      })
    ),
    specTitle: stringType()
  })
);

// Handle optional skipping logic
if (specs.skip) {
  specs.skip('skipSpecificationGroup', false);
}

// Define keyProductFeaturesModel
const keyProductFeaturesModel = shapeType({
  keyProductFeaturesItems: arrayType(
    shapeType({
      features: arrayType(
        shapeType({
          name: stringType(),
          refinementId: stringType(),
          refinementUrl: stringType(),
          value: stringType()
        })
      )
    })
  )
});

// Handle optional skipping logic
if (keyProductFeaturesModel.skip) {
  keyProductFeaturesModel.skip('skipKPF', false);
}

// Export the data model
const dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    keyProductFeatures: keyProductFeaturesModel,
    info: shapeType({
      productSubType: shapeType({
        name: stringType()
      })
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(
        shapeType({
          url: stringType(),
          type: stringType(),
          subType: stringType(),
          sizes: arrayType(stringType())
        })
      )
    }),
    specificationGroup: specs
  })
};

export default dataModel;
